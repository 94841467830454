<script>
import { useCms } from '/~/composables/cms/use-cms'
import { useUI } from '/~/composables/ui'
import LoyaltyCardFormV1 from '/~/drawer/loyalty-cards/components/card-form.vue'

export default {
  name: 'loyalty-cards-form-v2',
  extends: LoyaltyCardFormV1,
  setup() {
    const { isDarkThemeForEwallet } = useCms()
    const { isRequiredAsteriskVisible } = useUI()

    return {
      ...LoyaltyCardFormV1.setup?.(...arguments),
      isDarkThemeForEwallet,
      isRequiredAsteriskVisible,
    }
  },
}
</script>

<template>
  <validation-observer
    ref="form"
    v-slot="{ handleSubmit }"
    tag="form"
    class="flex min-h-full flex-col items-center justify-start"
    :disabled="isSubmitting"
    @submit.prevent="onSubmit"
    @keydown.enter="onSubmit"
  >
    <div class="text-md relative mx-auto w-full max-w-xs px-[15px]">
      <base-card v-if="localCard.isCatalog" class="overflow-hidden rounded-xl">
        <base-image v-bind="localCard.image" :alt="localCard.name" />
      </base-card>
      <base-card
        v-else
        class="relative flex-shrink-0 overflow-hidden rounded-xl"
        :style="{
          backgroundColor: localCard.color,
        }"
      >
        <div
          class="absolute rounded-full bg-white opacity-10"
          :style="{
            top: '-214px',
            right: '-163px',
            width: '351px',
            height: '351px',
          }"
        />
        <div
          class="absolute rounded-full bg-black opacity-10"
          :style="{
            top: '103px',
            left: '-71px',
            width: '218px',
            height: '218px',
          }"
        />
        <div
          class="relative flex h-full flex-col items-center justify-center p-6 font-bold text-white"
        >
          <base-icon :size="48" svg="v2/custom/star-filled" />
          <div class="text-2xl font-black">
            {{ localCard.name }}
          </div>
          <div class="font-bold uppercase">Custom card</div>
        </div>
      </base-card>
    </div>
    <div
      v-if="!localCard.isCatalog"
      class="flex w-full justify-around px-2.5 pt-[30px]"
    >
      <button
        v-for="color in loyaltyCardColors"
        :key="color.value"
        type="button"
        class="flex flex-shrink-0 cursor-pointer items-center justify-center rounded-full border-2 border-solid border-white bg-white disabled:opacity-50"
        :aria-label="`${color.name} color`"
        :style="{
          width: '32px',
          height: '32px',
          borderColor:
            color.value === localCard.raw.color ? localCard.raw.color : null,
        }"
        :disabled="isSubmitting"
        @click="onColorChange(color.value)"
      >
        <span
          class="block rounded-full"
          :style="{
            width: '24px',
            height: '24px',
            backgroundColor: color.value,
            borderColor: color.value,
          }"
        />
      </button>
    </div>

    <div
      class="mt-10 w-full"
      :style="{
        '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
      }"
    >
      <base-input
        v-if="!localCard.isCatalog"
        v-model="localCard.raw.name"
        :validation="{
          rules: 'required',
          name: 'Card Name',
        }"
        :error="backendErrors.name"
        :disabled="isSubmitting"
        label="Card Name"
        required
        name="name"
        :required-asterisk="isRequiredAsteriskVisible"
        entry-class="h-12 rounded-md"
      />

      <base-input
        v-model="localCard.raw.code"
        :validation="{
          rules: 'required|min:1|max:25|alpha_num',
          name: 'Card Number',
        }"
        :error="backendErrors.code"
        :disabled="isSubmitting"
        :mask="{ mask: /^[\d\w]{1,25}$/ }"
        label="Card Number"
        required
        name="code"
        :required-asterisk="isRequiredAsteriskVisible"
        entry-class="h-12 rounded-md"
      />
    </div>

    <div class="flex w-full flex-col">
      <div class="mb-2.5 w-full">Additional Photos</div>
      <div class="flex w-full justify-between space-x-5">
        <image-drop
          label="Front Image"
          :image="frontImage"
          :disabled="isSubmitting"
          removable
          icon="v2/box-icons/bx-image"
          :icon-size="48"
          @upload="onFrontChange"
        />

        <image-drop
          label="Back Image"
          :image="backImage"
          :disabled="isSubmitting"
          removable
          icon="v2/box-icons/bx-image"
          :icon-size="48"
          @upload="onBackChange"
        />
      </div>
    </div>

    <div class="mt-auto w-full pt-10">
      <base-button
        :loading="isSubmitting"
        full-width
        @click="handleSubmit(onSubmit)"
      >
        Save
      </base-button>
    </div>
  </validation-observer>
</template>
